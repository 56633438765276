import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from "./AuthContext";

export const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();
  if (!token) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};