import React from "react";
import _breakpoints from '../styles/_breakpoints.scss';
import _variables from '../styles/_variables.scss';
// import smallLogo from '../images/logo-sm-white.png';
// import largeLogo from '../images/logo-lg-white.png';


const logos = _variables;
const breaks = {
  small: parseInt(_breakpoints.small),
  medium: parseInt(_breakpoints.medium),
  large: parseInt(_breakpoints.large),
  extra: parseInt(_breakpoints.extra)
}


class Logo extends React.PureComponent {
  state = {
  image_width: null,
  image_height: null,
  image_src: null
}

  componentDidMount() {
    window.addEventListener('resize', this.setImage.bind(this))    
    this.setImage();
  };

  setImage = () => {
        // if(window.innerWidth <= breaks.small) {
        //   this.setState({image_width: logos.smallWidth, image_height: logos.smallHeight, image_src: smallLogo});
        // } else {
        //   this.setState({image_width: logos.largeWidth, image_height: logos.largeHeight, image_src: largeLogo})
        // }  
      }

  render() {
    return (
      <React.Fragment>
        <img src={this.state.image_src} width={this.state.image_width} height={this.state.image_height} /> 
      </React.Fragment>
    );
  }
}

export default Logo;