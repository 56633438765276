import CURAIServices from "../services/CURAIServices";
import CaseServices from "../services/CaseServices";
import React,  {useState, useEffect, useRef}  from "react";
import { Row, Col, Container, Button, Form, InputGroup, Card } from 'react-bootstrap';
import '../styles/documenteditor.scss';
import { Link, useLocation, useParams, useOutletContext } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import ITCBLogo from '../images/ITCBLogo.png';


const CaseEditor = () => {
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [key, setKey] = useState('order');

  useEffect(() => {
    if (!loaded) {
      setPageClass('cases')
      setLoaded(true);
      //Call Data Loaded API HERE
      if ((location.state == null || location.state == {}) && id != null) {
        CaseServices.getCase(id).then(response => {
          console.log(response);
          var contentBlocks = convertFromHTML(response.data.edits[0].html)
          var contentState = ContentState.createFromBlockArray(contentBlocks);
          var ed = EditorState.createWithContent( contentState );
          location.state = response.data;
          setEditorState(ed);
        });
      }
      else if (id != null) {
        console.log(location.state);
        var contentBlocks = convertFromHTML(location.state.response.edits[0].html)
        var contentState = ContentState.createFromBlockArray(contentBlocks);
        var ed = EditorState.createWithContent( contentState );
        setEditorState(ed);
      }
      else {
        //throw error here
      }
    }
  },[loaded]); 

  const getHtmlContent = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = convertToHTML(editorState.getCurrentContent());
    console.log(htmlContent);
    return htmlContent;
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  };

  const generateDoc=()=>{
    CURAIServices.saveAsDoc(getHtmlContent()).then(response => {
      console.log(response);
      // Get the content disposition from the response headers
      const contentDisposition = response.headers['content-disposition'];
      
      // Extract the filename from the content disposition
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      const fileName = fileNameMatch ? fileNameMatch[1] : 'download';

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a download link
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);      
    }).catch(err => {
      console.log(err);
    });
  }

  return (<>
    <Container fluid className='layout-height mt-3 pt-5'>
      <Row>
        <Col md={1}></Col>
        <Col md={7}>
          <h2 style={{marginBottom:'15px'}}>Step 2 of 2 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {id == null ? <></> : <small><Link className="viewCase" to={`/Cases/Draft/${id}`}>View Draft Settings</Link></small>}</h2>
          <h1 style={{marginBottom:'10px'}}>Review your Draft Order on Directions</h1>
          <h2 style={{marginBottom:'60px'}}>Edit and Review your Draft Order before saving.</h2>
        </Col>
        <Col md={4}>
          <Row style={{marginTop:'18px'}}>
            <Col md={7} className="company-text"><h3 className="float-end">The Industrial Tribunal of the <br />Commonwealth of The Bahamas</h3></Col>
            <Col md={5} className="company-logo"><img src={ITCBLogo} /></Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10} style={{border:'1px solid #F5F5F5'}}>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
        </Col>
        <Col md={1}></Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <Button variant="primary mt-5" onClick={generateDoc}>Save Draft Order On Directions</Button>
        </Col>
      </Row>
    </Container>
  </>);
};

export default CaseEditor;