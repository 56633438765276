/// This is the default layout page for the application. Sub directories may have separate layout files
import { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import Logo from '../components/logo';
import { useAuth } from "../helpers/AuthContext";
import UserService from "../services/UserServices";
import insideLogo from '../images/logoBlack.png';
import logotxt from '../images/insideLogoFull.png';
import { Col, Row, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import axios from 'axios';
import { PersonFill } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../styles/shared.scss';


const Layout = ({loading}) => {
  const { login, logout, user, token } = useAuth();
  const [ pageClass, setPageClass] = useState("");
  let location = useLocation();

  useEffect(() => {
    const regex = /[^\w\s]|\d/gi;
    let path = window.location.pathname;
    if (path = '/') {
      if (!token)
        setPageClass('login');
      else {
        if (!loading)
          UserService.isLoggedIn().then((response) => {
            if (response.data == true) {
              let p = window.location.pathname;
              if (p == '/')
                ;//setPageClass('landing')
              else
                setPageClass(p.toLowerCase().replace(regex, ''));
            }
            else
              logout();
          }
        );
      }
    }
    else
      setPageClass(path.toLowerCase().replace(regex, ''));
    console.log();
  }, [location.pathname]);

  const handleLogout = (event) => {
    event.preventDefault();
    UserService.logout().then(data => {
      logout();
    }).catch(err => {
      console.log(err);
    });
  }
  
  return (
    <div className={pageClass}>
      <header className="container">
        <Container>
          <Row>
            <Col md={1}></Col>
            <Col md={8}>
              <Link className="innerlogo" to="/">
                <img src={logotxt} />
              </Link>
            </Col>
            <Col md={3}>
              <Dropdown className='mainmenu'>
                <Dropdown.Toggle variant="success">
                  <PersonFill />&nbsp;{user?.firstName + ' ' + user?.lastName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/Cases">Manage Cases</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Users">Manage User</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Roles">Manage Roles</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Logout">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>            
            </Col>
          </Row>
        </Container>
      </header>
      <section className="layout-height">
        <Outlet context={[pageClass, setPageClass]} />
      </section>
      <footer className="container"></footer>
    </div>
  )
};

export default Layout;