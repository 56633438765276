import React,  {useState, useEffect}  from "react";
import validator from "validator";
import { Row, Col, Container, Button, Form, InputGroup } from 'react-bootstrap';
import { Key } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { useAuth } from "../helpers/AuthContext";
import UserService from "../services/UserServices";
import MicrosoftLogin from "react-microsoft-login";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const LoginForm = () => {
  const { login, logout, user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState( {} );
  const [showPass, setShowPass] = useState(false);
  

  const [YOUR_CLIENT_ID, setClientID] = useState("f8c7976f-3e93-482d-88a3-62a1133cbbc3");

  const validateEmail = (e) => {
    const email = e.target.value;
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      email: validator.isEmail(email) ? undefined : "Invalid email address"
    }));
  };

  const clickHandler = (e) => {

  };

  const validatePass = (e) => {
    const pass = e.target.value;
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      password: validator.isStrongPassword(pass, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })
        ? undefined
        : "Invalid password"
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    UserService.login(email, password).then(data => {
      login(data);
    }).catch(err => {
      console.log(err);
    });
  }

  const renderErrorMessage = (name) => name in errorMessages && (
    <>{errorMessages[name]}</>
  );

  const authHandler = (err, data) => {
    console.log(err, data);
  };

  const googleLoginSuccess = (response) => {
    console.log(response);
  };
  const googleLoginFailure = (response) => {
    console.log(response);
  };

  return <div className="form login">
    <Form onSubmit={handleSubmit}>
      <Container fluid>
        <Row className='h-100 mt-0 px-0 p-0'>
          <Col className='col-5 mt-0 px-0 p-0 inputarea'>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control type="email" placeholder="user@judi.com" onChange={(e) => setEmail(e.target.value)} value={email} onBlur={validateEmail} />
              </InputGroup>
              <Form.Text className="text-muted">{renderErrorMessage("email")}&nbsp;</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control type="password" placeholder="***********" required onChange={(e) => setPassword(e.target.value)} value={password} onBlur={validatePass} />
              </InputGroup>
              <Form.Text className="text-muted">{renderErrorMessage("password")}&nbsp;</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3 login d-flex justify-content-left">
              <Button variant="primary" type="submit">LOGIN</Button>
              <div className="passwordreminder">
                {/* <Form.Check type="checkbox" label="Remember Me" className="rememberme" /> */}
                <Form.Text className="text-muted forgetlink"><Link to="/forgotpassword">Forgot your password?</Link></Form.Text>
              </div>
            </Form.Group>
            {/* <Form.Group className="mb-3 forget_remember" controlId="formBasicCheckbox">
              <div className="passwordreminder">
                <Form.Check type="checkbox" label="Remember Me" className="rememberme" />
                <Form.Text className="text-muted forgetlink"><Link to="/forgotpassword">Forgot Password?</Link></Form.Text>
              </div>
            </Form.Group> */}
            {/* <Form.Group className="mb-3 register d-flex justify-content-center">
              <Form.Text>Do not have an account? <Link to="/register" className="registerlink">Register</Link></Form.Text>
            </Form.Group> 
            <Form.Group className="mb-3 login d-flex justify-content-center">
              <MicrosoftLogin clientId={YOUR_CLIENT_ID} authCallback={authHandler} buttonTheme='dark' />
            </Form.Group> */}
          </Col>
        </Row>
      </Container>
    </Form>
  </div>;
}

export default LoginForm;