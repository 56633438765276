import axios from "axios";

class RoleServices {
  getRoles() {
    return axios.get('Role/');
  }

  getRole(id) {
    return axios.get('Role/' + id);
  }

  addRole(Role) {
    return axios.post('Role/', {
      name: Role.firstName,
      description: Role.lastName,
      permissions: Role.permissions,
    });
  }

  updateRole(id, Role) {
    return axios.put('Role/' + id, {
      name: Role.firstName,
      description: Role.lastName,
      permissions: Role.permissions,
    });
  }

  deleteRole(id) {
    return axios.delete('Role/' + id);
  }
}


export default new RoleServices();