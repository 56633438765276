import {useState}  from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const useHistoryState = (initialValue) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [rawState, rawSetState] = useState(() => {
    const value = (location.state)?.[location.pathname];
    return value ?? initialValue;
  });
  function setState(value) {
    navigate(location.pathname, {
      state: {
        ...location.state,
        [location.pathname]: value,
      },
      replace: true
    });
    rawSetState(value);
  }
  return [rawState, setState];
}