import { useState, useEffect } from 'react';
import LoginForm from '../components/LoginForm';
import '../styles/login.scss';
import { useOutletContext } from 'react-router-dom';

const Login = () => {
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setPageClass('login');
      setLoaded(true);
    }
  },[loaded]); 
  
  return <>
    <LoginForm />
  </>;
};

export default Login;