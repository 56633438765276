import React,  {useEffect}  from "react";
import UserServices from "../services/UserServices";
import { useAuth } from "../helpers/AuthContext";

const Logout = () => {
  const { logout } = useAuth();
  // call this function to sign out logged in user
  useEffect(() => {
    // call api or anything
    UserServices.logout().then(data => {
      logout();
    }).catch(err => {
      console.log(err);
      logout();
    });
  });
  return <></>;
};

export default Logout;