import { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../styles/noauthlayout.scss';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Outlet } from 'react-router-dom';
import largeLogo from '../images/loginLogo.png'
//#548235


const NoAuthLayout = ({loading}) => {
  const [ pageClass, setPageClass] = useState("");

  return (
<div className={pageClass}>
  <section className="layout-height">
    <div className='layout-form'>
      <Container fluid className='layout-height'>
        <Row className='h-100 mt-0 px-0 p-0'>
          <Col className='col-7 mt-0 px-0 p-0'>
            <div className="flex-container">
              <div className="row"> 
                <div className="flex-item">
                  <div className='logo'><img src={largeLogo} /></div>
                </div>
                <div className="flex-item">
                  <Outlet context={[pageClass, setPageClass]} />
                </div>
              </div>
            </div>
          </Col>
          <Col className='col-5 mt-0 px-0 p-0 brain layout-height'></Col>
        </Row>
      </Container>      
    </div>
  </section>
</div>
  );
};

export default NoAuthLayout;