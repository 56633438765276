import React,  {useState}  from "react";
import validator from "validator";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { useAuth } from "../helpers/AuthContext";
import UserService from "../services/UserServices";
import { Col, Row } from "react-bootstrap";
import info from "../images/Information.png";

const RegistrationForm = () => {
  const { login } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpass, setConfirm] = useState("");
  const [accountType, setAccountType] = useState("");
  const [errorMessages, setErrorMessages] = useState( {} );


  const setName = (e) => {
    console.log(e);
    var names = e.split(/[\s,]+/);
    setFirstName(names.shift());
    setLastName(names.join(' '));
  }

  const validateName = (e) => {
    const name = validator.trim(e.target.value);
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      name: validator.matches(name, '[A-Za-z0-9 -]{2}') ? undefined : "Invalid name"
    }));
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      email: validator.isEmail(email) ? undefined : "Invalid email address"
    }));
  };

  const validatePass = (e) => {
    const pass = e.target.value;
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      password: validator.isStrongPassword(pass, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })
        ? undefined
        : "Invalid password"
    }));
  };

  const confirmPass = (e) => {
    const pass = e.target.value;
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      confirm: pass === password ? undefined : "Passwords do no match"
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    UserService.register(firstName, lastName, email, password, accountType).then(data => {
      login(data);
    }).catch(err => {
      console.log(err);
    });
  }

  const renderErrorMessage = (name) => name in errorMessages && (
    <>{errorMessages[name]}</>
  );

  return <div className="form registration">
  <Form onSubmit={handleSubmit}>
    <Form.Group className="mb-3" controlId="formName">
      <Form.Label>Name</Form.Label>
      <Form.Control type="text" placeholder="Enter name" onChange={(e) => setName(e.target.value)} onBlur={validateName} />
      <Form.Text className="text-muted">{renderErrorMessage("name")}&nbsp;</Form.Text>
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Email</Form.Label>
      <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} value={email} onBlur={validateEmail} />
      <Form.Text className="text-muted">{renderErrorMessage("email")}&nbsp;</Form.Text>
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <Form.Control type="password" placeholder="Password" required onChange={(e) => setPassword(e.target.value)} value={password} onBlur={validatePass} />
      <Form.Text className="text-muted">{renderErrorMessage("password")}&nbsp;</Form.Text>
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicConfirm">
      <Form.Label>Confirm Password</Form.Label>
      <Form.Control type="password" placeholder="Confirm Password" required onChange={(e) => setConfirm(e.target.value)} value={confirmpass} onBlur={confirmPass} />
      <Form.Text className="text-muted">{renderErrorMessage("confirm")}&nbsp;</Form.Text>
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicCheckbox">
      <div className="accounttype">
        <h4>Select Account Type<img src={info} /></h4>
        <Row>
          <Col className="p-1"><Form.Label><Form.Control type="radio" value='daily' name="type" id="daily" onChange={(e) => setAccountType(e.target.value)} />Daily</Form.Label></Col>
          <Col className="p-1"><Form.Label><Form.Control type="radio" value='weekly' name="type" id="weekly" onChange={(e) => setAccountType(e.target.value)} />Weekly</Form.Label></Col>
          <Col className="p-1"><Form.Label><Form.Control type="radio" value='monthly' name="type" id="monthly" onChange={(e) => setAccountType(e.target.value)} />Monthly</Form.Label></Col>
        </Row>
      </div>
    </Form.Group>
    <Form.Group className="mb-3 d-flex justify-content-center register">
      <Button variant="primary" type="submit">Register</Button>
    </Form.Group>
    <Form.Group className="mb-3 terms">
      <Form.Text>By creating an account you are in agreement with our </Form.Text>
      <Form.Text><Link to="/terms" className="termslink">terms and conditions.</Link></Form.Text>
    </Form.Group>
    <Form.Group className="mb-3 log-in d-flex justify-content-center">
      <Form.Text>Already have an account? <Link to="/login" className="registerlink">Log In</Link></Form.Text>
    </Form.Group>
  </Form>      
</div>;
}

export default RegistrationForm;