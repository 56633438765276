import CaseServices from "../services/CaseServices";
import React,  {useState, useEffect, useRef}  from "react";
import { Row, Col, Container, Button, Form, InputGroup, Card } from 'react-bootstrap';
import {Tab} from "bootstrap";
import '../styles/cases.scss';
import OrderForm from "../components/OrderForm";
import { Search } from "react-bootstrap-icons";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/mantine';
import { usePagination } from "@table-library/react-table-library/pagination";
import { useRowSelect } from "@table-library/react-table-library/select";
import { useCustom } from '@table-library/react-table-library/table';
import { useSort } from '@table-library/react-table-library/sort';
import Moment from 'react-moment';
import moment from 'moment';
import DOMPurify from 'dompurify';
import {Link, useNavigate} from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
import { JournalText } from "react-bootstrap-icons";

const Cases = () => {
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState("");
  const [isHide, setHide] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState({
    nodes: [],
    pageInfo: {
      page: 0, 
      size: 4, 
      offset: 0,
      pages: 0,
      total: 0
    },
  });

  const select = useRowSelect(data, {
    onChange: onSelectChange,
  });

  function onSelectChange(action, state) {
    console.log(action, state);
    setSelected(data.nodes.find( x => x.caseNumber == state.id));
  }

  const mantineTheme = getTheme({
    ...DEFAULT_OPTIONS,
    striped: true,
    highlightOnHover: true,
  });

  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns:  500px repeat(4, minmax(0, 1fr));

      margin: 16px 0px;
    `,
    HeaderRow: `
      background-color: #eaf5fd;
    `,
    Row: `
      &:nth-of-type(odd) {
        background-color: #d2e9fb;
      }

      &:nth-of-type(even) {
        background-color: #eaf5fd;
      }
    `,
    striped: true,
    highlightOnHover: true,
  };

  const theme = useTheme([mantineTheme, customTheme]);
  const resize = { resizerHighlight: '#dee2e6' };
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 4,
    },
    onChange: onPaginationChange,
  });
  function onPaginationChange(action, state) {
    console.log(action, state);
    data.pageInfo.page = state.page;
    data.pageInfo.offset = (state.page * state.size) + 1;
    setData(data);
  }
  //search
  useCustom('search', data, {
    state: { search },
    onChange: onSearchChange,
  });
  function onSearchChange(action, state) {
    console.log(action, state);
    pagination.fns.onSetPage(0);
  }
  let modifiedNodes = data.nodes;
  // filter
  modifiedNodes = isHide ? modifiedNodes.filter((node) => !node.isComplete) : modifiedNodes;

  //* Filter *//
  useCustom('filter', data, {
    state: { isHide },
    onChange: onFilterChange,
  });

  function onFilterChange(action, state) {
    console.log(action, state);
    pagination.fns.onSetPage(0);
  }
  //* Sort *//
/*
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <FaChevronUp />,
        iconDown: <FaChevronDown />,
      },
      sortFns: {
        TASK: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
        DEADLINE: (array) => array.sort((a, b) => a.deadline - b.deadline),
        TYPE: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
        COMPLETE: (array) => array.sort((a, b) => a.isComplete - b.isComplete),
        TASKS: (array) => array.sort((a, b) => (a.nodes || []).length - (b.nodes || []).length),
      },
    },
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }
*/


  useEffect(() => {
    if (!loaded) {
      setPageClass('cases');
      setLoaded(true);
      //Call Data Loaded API HERE
      CaseServices.getCases().then(response => {
        console.log(response);
        setData({
          nodes: response.data.map(row => { return { ...row, 'id': row.caseNumber }; }),
          pageInfo: {
            page: 0, 
            size: 4, 
            offset: 0,
            pages: parseInt(response.data.length / 4),
            total: response.data.length
          },
        });
      }).catch(err => {
        console.log(err);
      });
      var triggerTabList = [].slice.call(document.querySelectorAll('#pills-tab button'))
      triggerTabList.forEach(function (triggerEl) {
        var tabTrigger = new Tab(triggerEl)
      
        triggerEl.addEventListener('click', function (event) {
          event.preventDefault()
          tabTrigger.show()
        })
      });
    }
  },[loaded]); 

  function handleClick() {
    history.push('/Cases/' + selected.caseId);
  }

  const COLUMNS = [
    { label: "Case Title", renderCell: (item) => item.caseTitle },
    { label: "Case ID", renderCell: (item) => item.caseNumber },
    { label: "Case Type", renderCell: (item) => item.caseType },
    { label: "Case Date", renderCell: (item) => moment(item.createdDate).format('MMMM Do YYYY, h:mm:ss a') },
    { label: "View", renderCell: (item) => { 
      return <Link className="viewCase" to={`/Cases/${item.caseId}`}>View Case</Link>
     } },
  ];
  
  const navigate = useNavigate();
  const toOrder=()=>{
    navigate('/Cases/'+ selected.caseId);
  }

  const addCase = () => {
    navigate('/Cases/Draft', { state: null}); //intentional clear state
  }

  return (<>
    <Container fluid className='layout-height mt-5 pt-5'>
      <Row>
        <Col className='col-3'>
          <Form.Group className="mb-3 px-2 text-end" controlId="formBasicSearchText">
            <Form.Label>Case Search:</Form.Label>
          </Form.Group>
        </Col>
        <Col className='col-5'>
          <Form.Group className="mb-3" controlId="formBasicSearch">
            <InputGroup>
              <InputGroup.Text id="basic-addon1"><Search className="searchicon" /></InputGroup.Text>
              <Form.Control type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col className='col-4'>
          <Form.Group className="mb-3 px-2 text-start add-case" controlId="formBasicAdd">
            <Button variant="outlined mx-3" onClick={addCase}><JournalText color="green" size={25} />&nbsp;Add New</Button>
          </Form.Group>
        </Col>
      </Row>
      <Row className="caseslist">
        <Col md={1}></Col>
        <Col md={9}>
          <CompactTable columns={COLUMNS} data={data} theme={theme} select={select} pagination={pagination} layout={{ custom: true }} />
          <br />
          <div style={{ display: "flex", justifyContent: "space-between", }}>
            <span>Total Rows: {data.pageInfo.total}</span>
            <span>
              Rows: {(pagination.state.page * data.pageInfo.size) + 1}
              {"-"}
              {(pagination.state.page + 1) * data.pageInfo.size}
              {" of "}
              {data.pageInfo.total}{" "}
              <button type="button" disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(0)}>
                {"|<"}
              </button>
              <button type="button" disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(pagination.state.page - 1) }>
                {"<"}
              </button>
              <button type="button" disabled={pagination.state.page + 1 === data.pageInfo.pages} onClick={() => pagination.fns.onSetPage(pagination.state.page + 1) }>
                {">"}
              </button>
              <button type="button" disabled={pagination.state.page + 1 === data.pageInfo.pages} onClick={() => pagination.fns.onSetPage(data.pageInfo.pages - 1) }>
                {">|"}
              </button>
            </span>
          </div>
        </Col>
      </Row>
      {selected != null ?
      <Row key={selected.caseId}>
        <Col md={1}></Col>
        <Col md={9}>
          {/* <Card style={{ margin:'40px 0' }}>
            <Card.Body>
              <Card.Title>Edits</Card.Title>
              <Row>
                <Col>
                Test
                </Col>
              </Row> 
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
      : ''}
    </Container>
  </>);
};

export default Cases;