import CURAIServices from "../services/CURAIServices";
import CaseServices from "../services/CaseServices";
import React,  {useState, useEffect, useRef}  from "react";
import { Row, Col, Container, Button, Form, InputGroup, Card } from 'react-bootstrap';
import {Tab} from "bootstrap";
import '../styles/casesdraft.scss';
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Link, useNavigate, useParams, useOutletContext } from 'react-router-dom';
import ITCBLogo from '../images/ITCBLogo.png';
import { useHistoryState } from "../helpers/useHistoryState";

const DraftBuilder = () => {
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const [errorMessages, setErrorMessages] = useState( {} );
  const { id } = useParams();  
  const [caseData, setCaseData] = useHistoryState({
    caseNumber: '',
    judge: '',
    title: '',
    court: 'Bahamas Industrial Tribunal',
    country: 'The Commonwealth of The Bahamas',
    draft: '',
    respondent: '',
    applicant: '',
    appearances_for_respondent: [''],
    appearances_for_applicant: [''],
    orders: '',
  });

  const toCamel = (o) =>  {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = toCamel(value)
          }
          return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = o[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = toCamel(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  }

  useEffect(() => {
    if (!loaded) {
      setPageClass('casesdraft')
      setLoaded(true);
      //Call Data Loaded API HERE
      if (id != null) {
        CaseServices.getCase(id).then(response => {
          console.log(response);
          var inputs = response.data.inputs;
          var entry = JSON.parse(inputs[inputs.length-1].inputData);
          console.log(entry);
          setCaseData(toCamel(entry));
        });
      }
    }
  },[loaded]); 

  const renderErrorMessage = (name) => name in errorMessages && (
    <>{errorMessages[name]}</>
  );

  const changeCurrent = (e, property, value) => {
    console.log(e.target.value)
    if (property == 'appearances_for_respondent' || property == 'appearances_for_applicant') {
      var ary = caseData[property];
      ary[value] = e.target.value
      setCaseData({
        ...caseData,
        [property]:ary
      });
    } else {
      setCaseData({
        ...caseData,
        [property]:e.target.value
      });
    }
  };

  const navigate = useNavigate();
  const toOrder=()=>{
    navigate('/order/'+ selected.caseNumber,{state:{data: selected}});
  }

  const validateCurrent = (e, property, value) => {
    console.log(e.target.value)
    if (e.target.value == '') {
      setErrorMessages({
        ...errorMessages,
        [property]:value
      });
    }
    else {
      setErrorMessages({
        ...errorMessages,
        [property]:''
      });
    }

    var data = {...caseData };
    data.appearances_for_applicant = data.appearances_for_applicant.filter((row)=> row != '' );
    if (data.appearances_for_applicant.length == 0)
      data.appearances_for_applicant.push('');

    data.appearances_for_respondent = data.appearances_for_respondent.filter((row)=> row != '' );
    if (data.appearances_for_respondent.length == 0)
      data.appearances_for_respondent.push('');
    setCaseData(data);
  };

  const errors = {
    caseNumber: 'Please enter a Case Number',
    judge: 'Please enter the Presiding Judge name',
    title: 'Please enter a Title for the case',
    court: 'Please enter Court information',
    country: 'The Commonwealth of The Bahamas',
    draft: 'Please enter Draft Details',
    respondent: 'Please enter the Respondent Name',
    applicant: 'Please enter the Applicant Name',
    appearances_for_respondent: 'Please enter the Appearences of Respondents',
    appearances_for_applicant: 'Please enter the Appearences of Applicants',
    orders: '',
  };

  const saveChanges= () => {
    var errorMessages = {} ;
    if (caseData.caseNumber == '')
      errorMessages.caseNumber = errors.caseNumber;
    if (caseData.country == '')
      errorMessages.country = errors.country;
    if (caseData.court == '')
      errorMessages.court = errors.court;
    // if (caseData.draft == '')
    //   errorMessages.draft = errors.draft;
    if (caseData.judge == '')
      errorMessages.judge = errors.judge;
    if (caseData.title == '')
      errorMessages.title = errors.title;

    setErrorMessages(errorMessages);
    if (Object.keys(errorMessages).length != 0)
      return;

    CURAIServices.generateDocument(caseData).then(response => {
      console.log(response);
      navigate('/cases/'+response.data.caseId,{state:{response: response.data, request: caseData}});
    }).catch(err => {
      console.log(err);
    });
  }

  const addArrayEntry = (e, property) => {
    console.log(property,e);
    var data = {...caseData };
    data[property].push('');
    setCaseData(data);
  }

  const delArrayEntry = (e, property, index) => {
    console.log(property,e);
    var data = {...caseData };
    data[property].splice(index, 1);
    setCaseData(data);
  }

  return (<>
    <Container fluid className='layout-height mt-3 pt-5'>
      <Row>
        <Col md={1}></Col>
        <Col md={7}>
          <h2 style={{marginBottom:'15px'}}>Step 1 of 2 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {id == null ? <></> : <small><Link className="viewCase" to={`/Cases/${id}`}>View Output</Link></small>}</h2> 
          <h1 style={{marginBottom:'10px'}}>Generate Draft Order on Directions</h1>
          <h2 style={{marginBottom:'60px'}}>Fill in the details below to generate a draft order.</h2>
        </Col>
        <Col md={4}>
          <Row style={{marginTop:'18px'}}>
            <Col md={7} className="company-text"><h3 className="float-end">The Industrial Tribunal of the <br />Commonwealth of The Bahamas</h3></Col>
            <Col md={5} className="company-logo"><img src={ITCBLogo} /></Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <Form.Group className="mb-3" controlId="formCaseNumber">
            <Form.Label>Case Number</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Enter Case Number e.g. NX1111-11" onChange={(e) => changeCurrent(e, 'caseNumber', e.target.value)} value={caseData.caseNumber} onBlur={(e) => validateCurrent(e, 'caseNumber', errors.caseNumber)} />
            </InputGroup>
            <Form.Text className="text-muted">{renderErrorMessage("caseNumber")}&nbsp;</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCaseTitle">
            <Form.Label>Title</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Enter Case Title e.g. Dylan vs. Viva Wyndham" onChange={(e) => changeCurrent(e, 'title', e.target.value)} value={caseData.title} onBlur={(e) => validateCurrent(e, 'title', errors.title)} />
            </InputGroup>
            <Form.Text className="text-muted">{renderErrorMessage("title")}&nbsp;</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCaseCourt">
            <Form.Label>Court</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Enter Court Information e.g. Bahamas Industrial Tribunal" onChange={(e) => changeCurrent(e, 'court', e.target.value)} value={caseData.court} onBlur={(e) => validateCurrent(e, 'court', errors.court)} />
            </InputGroup>
            <Form.Text className="text-muted">{renderErrorMessage("court")}&nbsp;</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCaseCountry">
            <Form.Label>Country</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Enter Country e.g. The Commonwealth of The Bahamas" onChange={(e) => changeCurrent(e, 'country', e.target.value)} value={caseData.country} onBlur={(e) => validateCurrent(e, 'country', errors.country)} />
            </InputGroup>
            <Form.Text className="text-muted">{renderErrorMessage("country")}&nbsp;</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCaseJudge">
            <Form.Label>Judge</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Enter Judge Name e.g. Dylon Aslan" onChange={(e) => changeCurrent(e, 'judge', e.target.value)} value={caseData.judge} onBlur={(e) => validateCurrent(e, 'judge', errors.judge)} />
            </InputGroup>
            <Form.Text className="text-muted">{renderErrorMessage("judge")}&nbsp;</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formRespondent">
            <Form.Label>Respondent</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Enter the name of the Repondent" onChange={(e) => changeCurrent(e, 'respondent', e.target.value)} value={caseData.respondent} onBlur={(e) => validateCurrent(e, 'respondent', errors.respondent)} />
            </InputGroup>
            <Form.Text className="text-muted">{renderErrorMessage("respondent")}&nbsp;</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formApplicant">
            <Form.Label>Applicant</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Enter the name of the Applicant" onChange={(e) => changeCurrent(e, 'applicant', e.target.value)} value={caseData.applicant} onBlur={(e) => validateCurrent(e, 'applicant', errors.applicant)} />
            </InputGroup>
            <Form.Text className="text-muted">{renderErrorMessage("applicant")}&nbsp;</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formRespondentApp">
            <Form.Label>Appearances for Respondent</Form.Label>
            <Row>
              {caseData.appearances_for_respondent.map((row, i) => <>
              <Col md={12} key={"res_"+i}>
                <InputGroup>
                  <Form.Control type="text" placeholder="Respondent Appearance" onChange={(e) => changeCurrent(e, 'appearances_for_respondent', i)} value={caseData.appearances_for_respondent[i]} onBlur={(e) => validateCurrent(e, 'appearances_for_respondent', errors.appearances_for_respondent)} />
                  <Button onClick={(e) => addArrayEntry(e, 'appearances_for_respondent') } className="add"><FaPlusCircle /></Button>
                </InputGroup>
                {(i > 0) ?
                  <Button onClick={(e) => delArrayEntry(e, 'appearances_for_respondent', i) } className="del">Remove</Button>
                :<></>}
              </Col>
              <Form.Text className="text-muted">{renderErrorMessage("appearances_for_respondent")}&nbsp;</Form.Text>
              </>)}
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formApplicantApp">
            <Form.Label>Appearances for Applicant</Form.Label>
            <Row>
              {caseData.appearances_for_applicant.map((row, i) => <>
              <Col md={12} key={"app_"+i}>
                <InputGroup>
                  <Form.Control type="text" placeholder="Applicant Appearance" onChange={(e) => changeCurrent(e, 'appearances_for_applicant', i)} value={caseData.appearances_for_applicant[i]} onBlur={(e) => validateCurrent(e, 'appearances_for_applicant', errors.appearances_for_applicant)} />
                  {(i > 0) ? 
                  <>
                    <Button onClick={(e) => addArrayEntry(e, 'appearances_for_applicant') } className="add" style={{marginRight:"10px"}}><FaPlusCircle /></Button>
                    <Button onClick={(e) => delArrayEntry(e, 'appearances_for_applicant', i) } className="del"><FaMinusCircle /></Button>
                  </>
                  :
                  <Button onClick={(e) => addArrayEntry(e, 'appearances_for_applicant') } className="add"><FaPlusCircle /></Button>
                  }
                </InputGroup>
              </Col>
              <Form.Text className="text-muted">{renderErrorMessage("appearances_for_applicant")}&nbsp;</Form.Text>
            </>)}
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCaseOrders">
            <Form.Label>Orders</Form.Label>
            <InputGroup>
              <Form.Control as="textarea" rows={5} placeholder="Enter the specific Orders for this case" onChange={(e) => changeCurrent(e, 'orders', e.target.value)} value={caseData.orders} onBlur={(e) => validateCurrent(e, 'orders', errors.orders)} />
            </InputGroup>
            <Form.Text className="text-muted">{renderErrorMessage("orders")}&nbsp;</Form.Text>
          </Form.Group>
        </Col>
        <Col md={1}></Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <Button variant="primary mt-5" onClick={saveChanges}>Generate</Button>
        </Col>
      </Row>
    </Container>
  </>);
};

export default DraftBuilder;