import { useAuth } from "../helpers/AuthContext";
import CURAIServices from "../services/CURAIServices";
import React,  {useState, useEffect, useRef}  from "react";
import { Row, Col, Container, Button, Form, InputGroup, Card } from 'react-bootstrap';
import '../styles/order.scss';
import Moment from 'react-moment';
import moment from 'moment';
import { useLocation, useParams, useOutletContext } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
//import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import htmlToDraft from 'html-to-draftjs';


const Order = () => {
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [key, setKey] = useState('order');

  useEffect(() => {
    if (!loaded) {
      setPageClass('order');
      setLoaded(true);
      //Call Data Loaded API HERE
      CURAIServices.getDocument(10).then(response => {
        var contentBlocks = convertFromHTML(response.data)
        var contentState = ContentState.createFromBlockArray(contentBlocks);
        var ed = EditorState.createWithContent( contentState );
        setEditorState(ed);
      });
    }
  },[loaded]); 

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  };

  return (<>
    <Container fluid className='layout-height mt-5 pt-5'>
    <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
      <Tab eventKey="order" title="Generated Order">
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
        />
      </Tab>
      <Tab eventKey="details" title="Case Details">
        {/* {id}{location.state.data.title} */}
        <Card style={{ margin:'40px 0' }}>
          <Card.Body>
            <Card.Title>Case Details</Card.Title>
            <Row>
              <Col md={2} className="label">Title:</Col><Col md={4}>{location.state.data.title}</Col>
              <Col md={2} className="label">Court</Col><Col md={4}>{location.state.data.court?.name}</Col>
              <Col md={2} className="label">Case No</Col><Col md={4}>{location.state.data.caseNumber}</Col>
              <Col md={2} className="label">Date</Col><Col md={4}><Moment format="MMMM Do YYYY, h:mm:ss a">{location.state.data.createdDate}</Moment></Col>
              <Col md={2} className="label">Judge:</Col>
              <Col md={4}>{
                  location.state.data.judge_Cases_AssignedJudgeIdToJudge != null ? location.state.data.judge_Cases_AssignedJudgeIdToJudge.person.firstName + ' ' + location.state.data.judge_Cases_AssignedJudgeIdToJudge.person.lastName :
                  location.state.data.judge_Cases_DispositionJudgeIdToJudge != null ? location.state.data.judge_Cases_DispositionJudgeIdToJudge.person.firstName + ' ' + location.state.data.judge_Cases_DispositionJudgeIdToJudge.person.lastName : ''
              }</Col>
              <Col md={2} className="label">Plaintiff:</Col>
              <Col md={4}>
                {location.state.data.parties.map((party, idx) => {
                  if (party != null && party.isPlaintiff) {
                    return <Card.Text as='div' className="plantiff" key={'plaintiff' + '_' + location.state.data.caseId + '_' + idx}>{party.person?.firstName + ' ' + party.person?.lastName}</Card.Text>
                  }
                })}
              </Col>
            </Row> 
            <blockquote style={{'minHeight':'300px', 'margin' : '20px 0'}}>
              <Card.Title>Notes</Card.Title>
              {location.state.data.notes.map((note, idx) => <>
                <h5>{note.notesSubject}</h5>
                <h6><Moment format="MMMM Do YYYY, h:mm:ss a">{note.createdDate}</Moment></h6>
                <Card.Text as='div' className="notes" key={'notes' + '_' + location.state.data.caseId + '_' + idx} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(note.body)}}></Card.Text>
              </>)}
            </blockquote>
          </Card.Body>
        </Card>
      </Tab>
    </Tabs>      
      <Row>
      </Row>
    </Container>
  </>);
};

export default Order;