import axios from "axios";

class CURAIServices {
  getCases() {
    return axios.get('CURAI/');
  }

  getCase(id) {
    return axios.get('CURAI/' + id);
  }

  getDocument(id) {
    return axios.get('CURAI/document');
  }

  generateDocument(CaseDetails) {
    return axios.post('CURAI/generate', CaseDetails);
  }

  saveAsDoc(HTML) {
    return axios.post('CURAI/SaveAsDocument', HTML, { responseType: 'blob', /* specify that the response should be treated as a Blob */ });
  }

  SaveAsDocument
}


export default new CURAIServices();