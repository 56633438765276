import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./helpers/ProtectedRoute";

import logo from './logo.svg';
import './App.scss';
import Layout from "./pages/Layout";
import Login from "./pages/Login";
// import Dashboard from './pages/Dashboard';
import Landing from './pages/Landing';
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import Order from './pages/Order';
import Four04 from "./pages/Four04";
import Logout from "./pages/Logout";
import Cases  from "./pages/Cases";
import DraftBuilder  from "./pages/DraftBuilder";
import CaseEditor from "./pages/CaseEditor";
import Registration from "./pages/Registration";
import { AuthProvider } from "./helpers/AuthContext";
import NoAuthLayout from "./pages/NoAuthLayout";

import { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function App() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // Add a request interceptor
  axios.defaults.baseURL = process.env.REACT_APP_APP_API_ENDPOINT;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.interceptors.request.use(function (config) {
    const token = window.localStorage.getItem("token");
    console.log(token);
    if (!token) {
      return config
    }
    config.headers.Authorization = "Bearer " + JSON.parse(token);
    setLoading(true);
  
    return config;
  }, function (error) {
    setLoading(false);
    setShow(true)
    setErrorMessage("Error sending request");
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use(function (response) {
    setLoading(false);
    return response;
  }, function (error) {
    setLoading(false);
    setShow(true)
    setErrorMessage("Error " + error.response.status + " from server");
    return Promise.reject(error);
  });

  useEffect(() => {
    document.title = 'JUDI - Artifical Intelligence for Real Justice';
  }, []);

  return (
    <div>
    {loading == true ? 
    <div className='loading-indicator'>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    : <></>
    }
    <ToastContainer className="position-absolute" position='top-center'>
      <Toast onClose={() => setShow(false)} show={show} bg='danger' style={{marginRight:'50px',marginTop:'25px'}}>
        <Toast.Header>
          <strong className="me-auto">Error Processing Request</strong>
        </Toast.Header>
        <Toast.Body>
          <strong>An error has occured while processing a request</strong>
          <small className="text-muted"></small>
          <br />
          {errorMessage}
        </Toast.Body>
      </Toast>
    </ToastContainer>      
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<NoAuthLayout state={loading} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout state={loading} />}>
              {/* <Route path="/" element={<Landing />} /> */}
              <Route path="/" element={<Cases />} />
              {/* <Route path="/Dashboard" element={<Dashboard />} /> */}
              <Route path="/Cases" element={<Cases />} />
              <Route path="/Cases/Draft/:id?" element={<DraftBuilder />} />
              <Route path="/Cases/:id" element={<CaseEditor />} />
              <Route path="/Order/:id" element={<Order />} />
              <Route path="/Users" element={<Users />} />
              <Route path="/Roles" element={<Roles />} />
              <Route path="/Logout" element={<Logout />} />
            </Route>
          </Route>
          <Route path="*" element={<Four04 />} />
        </Routes>
      </AuthProvider>      
    </BrowserRouter>
    </div>
  );
}

export default App; 