import axios from "axios";

class CaseServices {
  getCases() {
    return axios.get('Document/');
  }

  getCase(id) {
    return axios.get('Document/' + id);
  }
}


export default new CaseServices();