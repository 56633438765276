import validator from "validator";
import React,  {useState, useEffect}  from "react";
import UserServices from "../services/UserServices";
import { Row, Col, Container, Button, Form, InputGroup, Card } from 'react-bootstrap';
import {Tab} from "bootstrap";
import '../styles/users.scss';
import { Search, Key, PersonAdd, PersonDash } from "react-bootstrap-icons";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/mantine';
import { usePagination } from "@table-library/react-table-library/pagination";
import { useRowSelect } from "@table-library/react-table-library/select";
import { useCustom } from '@table-library/react-table-library/table';
import { useSort } from '@table-library/react-table-library/sort';
import moment from 'moment';
import { useOutletContext } from "react-router-dom";


const Users = () => {
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(null);
  const [errorMessages, setErrorMessages] = useState( {} );
  const [data, setData] = useState({
    nodes: [],
    pageInfo: {
      page: 0, 
      size: 4, 
      offset: 0,
      pages: 0,
      total: 0
    },
  });

  const select = useRowSelect(data, {
    onChange: onSelectChange,
  });

  function onSelectChange(action, state) {
    console.log(action, state);
    setSelected(data.nodes.find( x => x.id == state.id));
  }

  const COLUMNS = [
    { label: "FirstName", renderCell: (item) => item.firstName, select: true, sort: { sortKey: "FIRSTNAME" }, },
    { label: "LastName", renderCell: (item) => item.lastName, sort: { sortKey: "LASTNAME" }, },
    { label: "Username", renderCell: (item) => item.email, sort: { sortKey: "EMAIL" }, },
    { label: "Role", renderCell: (item) => item.role, sort: { sortKey: "ROLE" }, },
    { label: "Updated", renderCell: (item) => moment(item.updatedDate).format('LLL'), sort: { sortKey: "DATE" }, }
  ];
  
  const mantineTheme = getTheme({
    ...DEFAULT_OPTIONS,
    striped: true,
    highlightOnHover: true,
  });

  const getTableTheme = (columns) => {  
    return `
       --data-table-library_grid-template-columns:  30px repeat(${columns}, minmax(100px, 1fr));

       margin: 16px 0px;
     `;
  }

  const customTheme = {
    Table: getTableTheme(COLUMNS.length),
    HeaderRow: `
      background-color: #eaf5fd;
    `,
    Row: `
      &:nth-of-type(odd) {
        background-color: #d2e9fb;
      }

      &:nth-of-type(even) {
        background-color: #eaf5fd;
      }
    `,
    striped: true,
    highlightOnHover: true,
  };

  const theme = useTheme([mantineTheme, customTheme]);
  const resize = { resizerHighlight: '#dee2e6' };
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 4,
    },
    onChange: onPaginationChange,
  });
  
  function onPaginationChange(action, state) {
    console.log(action, state);
    data.pageInfo.page = state.page;
    data.pageInfo.offset = (state.page * state.size) + 1;
    setData(data);
  }
  
  //search
  let usersList = data;
  usersList = {
    nodes: data.nodes.filter((item) => item.firstName.toLowerCase().includes(search.toLowerCase()) || item.lastName.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase()) || item.role?.toLowerCase().includes(search.toLowerCase())),
  };

  useCustom('search', data, {
    state: { search },
    onChange: onSearchChange,
  });
  function onSearchChange(action, state) {
    console.log(action, state);
    console.log(usersList);
    pagination.fns.onSetPage(0);
  }

  //* Sort *//
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortFns: {
        FIRSTNAME: (array) => array.sort((a, b) => a.firstName.localeCompare(b.firstName)),
        LASTNAME: (array) => array.sort((a, b) => a.lastName.localeCompare(b.lastName)),
        EMAIL: (array) => array.sort((a, b) => a.email.localeCompare(b.email)),
        ROLE: (array) => array.sort((a, b) => a.role.localeCompare(b.role)),
        DATE: (array) => array.sort((a, b) => a.updatedDate > b.updatedDate),
      },
    },
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const renderErrorMessage = (name) => name in errorMessages && (
    <>{errorMessages[name]}</>
  );

  const changeCurrent = (e, property, value) => {
    console.log(e.target.value)    
    setSelected({
      ...selected,
      [property]:value
    });
  };


  const validateEmail = (e) => {
    const email = e.target.value;
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      email: validator.isEmail(email) ? undefined : "Invalid email address"
    }));
  };

  const validatePass = (e) => {
    const pass = e.target.value;
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      password: pass == "" || validator.isStrongPassword(pass, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })
        ? undefined
        : "Invalid password"
    }));
  };

  const confirmPass = (e) => {
    const pass = e.target.value;
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      confirmPassword: pass == selected.password
        ? undefined
        : "Passwords do not match"
    }));
  };

  useEffect(() => {
    if (!loaded) {
      setPageClass('users')
      setLoaded(true);
      //Call Data Loaded API HERE
      UserServices.getUsers().then(response => {
        console.log(response);
        usersList = response.data;
        setData({
          nodes: response.data,
          pageInfo: {
            page: 0, 
            size: 4, 
            offset: 0,
            pages: parseInt(response.data.length / 4),
            total: response.data.length
          },
        });
      }).catch(err => {
        console.log(err);
      });
      var triggerTabList = [].slice.call(document.querySelectorAll('#pills-tab button'))
      triggerTabList.forEach(function (triggerEl) {
        var tabTrigger = new Tab(triggerEl)
      
        triggerEl.addEventListener('click', function (event) {
          event.preventDefault()
          tabTrigger.show()
        })
      });
    }
  },[loaded]); 

  const addUser=()=>{
    setSelected({
      firstName:"",
      lastName:"",
      role:"",
      locked:false,
      password:"",
      confirmPassword:""
    });
  }
  const deleteSelected=()=>{
    if (selected.id == null)
      return;

    var userId = selected.id;
    setSelected(null);
    UserServices.deleteUser(userId).then(response => {
      console.log(response);
      setData({
        nodes : data.nodes.filter(object => object.id !== userId),
        pageInfo: {
          ...data.pageInfo,
          pages: parseInt((data.pageInfo.total - 1) / 4),
          total: data.pageInfo.total - 1
        }
      });
    }).catch(err => {
      console.log(err);
    });
  }

  const saveChanges=()=>{
    if (selected.id == null) {
      UserServices.addUser(selected).then(response => {
        console.log(response);
        setData({
          nodes : [...data.nodes, response.data],
          pageInfo: {
            ...data.pageInfo,
            pages: parseInt((data.pageInfo.total + 1) / 4),
            total: data.pageInfo.total + 1
          }
        });
      }).catch(err => {
        console.log(err);
      });
    } else {
      UserServices.updateUser(selected.id, selected).then(response => {
        console.log(response);
        setData({
          nodes : data.nodes.map(object => {
            if(object.id === response.data.id) {
              return {
                ...response.data,
              }
            }
            else return object;
          }),
          pageInfo: {
            ...data.pageInfo
          }
        });
      }).catch(err => {
        console.log(err);
      });
    }
  }

  return (<>
    <Container fluid className='layout-height mt-5 pt-5'>
      <Row>
        <Col className='col-3'>
          <Form.Group className="mb-3 px-2 text-end" controlId="formBasicEmail">
            <Form.Label>User Search:</Form.Label>
          </Form.Group>
        </Col>
        <Col className='col-4'>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <InputGroup>
              <InputGroup.Text id="basic-addon1"><Search className="searchicon" /></InputGroup.Text>
              <Form.Control type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col className='col-3'>
          <Form.Group className="mb-3 px-2 text-end" controlId="formBasicEmail">
            <Button variant="outlined mx-3" onClick={addUser}><PersonAdd color="green" size={25} />&nbsp;Add</Button>
            <Button variant="outlined" onClick={deleteSelected} disabled={selected == null}><PersonDash color="#1976d2" size={25} />&nbsp;Delete</Button>
          </Form.Group>
        </Col>
      </Row>
      <Row className="userlist">
        <Col md={1}></Col>
        <Col md={9}>
          <CompactTable columns={COLUMNS} data={usersList} theme={theme} select={select} pagination={pagination} layout={{ custom: true }} sort={sort} />
          <br />
          <div style={{ display: "flex", justifyContent: "space-between", }}>
            <span>Total Rows: {data.pageInfo.total}</span>
            <span>
              Page: {pagination.state.page + 1}
              {" of "}
              {Math.ceil(data.pageInfo.total / data.pageInfo.size)}{" "}
              <button type="button" disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(0)}>
                {"|<"}
              </button>
              <button type="button" disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(pagination.state.page - 1) }>
                {"<"}
              </button>
              <button type="button" disabled={pagination.state.page === data.pageInfo.pages} onClick={() => pagination.fns.onSetPage(pagination.state.page + 1) }>
                {">"}
              </button>
              <button type="button" disabled={pagination.state.page === data.pageInfo.pages} onClick={() => pagination.fns.onSetPage(data.pageInfo.pages) }>
                {">|"}
              </button>
            </span>
          </div>
        </Col>
      </Row>
      {selected != null ?
      <Row key={selected.id}>
        <Col md={1}></Col>
        <Col md={9}>
          <Card style={{ margin:'40px 0' }}>
            <Card.Body>
              <Card.Title>User Details</Card.Title>
              <Row>
                <Col md={2} className="label">First Name:</Col>
                <Col md={4}>
                  <InputGroup>
                    <Form.Control type="text" placeholder="Enter first name" onChange={(e) => changeCurrent(e, 'firstName', e.target.value)} value={selected.firstName} />
                  </InputGroup>
                </Col>
                <Col md={2} className="label">Email:</Col>
                <Col md={4}>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                    <Form.Control type="text" placeholder="Enter email addres" onChange={(e) => changeCurrent(e, 'email', e.target.value)} value={selected.email} onBlur={validateEmail} />
                  </InputGroup>
                  <Form.Text className="text-muted">{renderErrorMessage("email")}&nbsp;</Form.Text>
                </Col>
                <Col md={2} className="label">Last Name:</Col>
                <Col md={4}>
                  <InputGroup>
                    <Form.Control type="text" placeholder="Enter last name" onChange={(e) => changeCurrent(e, 'lastName', e.target.value)} value={selected.lastName} />
                  </InputGroup>
                </Col>
                <Col md={2} className="label">Password:</Col>
                <Col md={4}>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1"><Key className="passwordicon" /></InputGroup.Text>
                    <Form.Control type="password" placeholder="**********" onChange={(e) => changeCurrent(e, 'password', e.target.value)} value={selected.password} onBlur={validatePass} />
                  </InputGroup>
                  <Form.Text className="text-muted">{renderErrorMessage("password")}&nbsp;</Form.Text>
                </Col>
                <Col md={2} className="label">Role:</Col>
                <Col md={4}>
                  <InputGroup>
                    <Form.Control type="text" placeholder="Select a role" onChange={(e) => changeCurrent(e, 'role', e.target.value)} value={selected.role} />
                  </InputGroup>
                </Col>
                <Col md={2} className="label">Confirm Password:</Col>
                <Col md={4}>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1"><Key className="passwordicon" /></InputGroup.Text>
                    <Form.Control type="password" placeholder="**********" onChange={(e) => changeCurrent(e, 'confirmPassword', e.target.value)} value={selected.confirmPassword} onBlur={confirmPass} />
                  </InputGroup>
                  <Form.Text className="text-muted">{renderErrorMessage("confirmPassword")}&nbsp;</Form.Text>
                </Col>
                <Col md={2} className="label">Active:</Col>
                <Col md={4}>
                  <InputGroup>
                    <Form.Check type="switch" id="active" onChange={(e) => changeCurrent(e, 'locked', !selected.locked)} checked={selected.locked==false} />
                  </InputGroup>
                </Col>
              </Row>
              <Button variant="primary mt-5" onClick={saveChanges}>Save Changes</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      : ''}
    </Container>
  </>);
};

export default Users;