import { useState, useEffect } from 'react';
import RegistrationForm from '../components/RegistrationForm';
import '../styles/registration.scss';
import { useOutletContext } from "react-router-dom";

const Registration = () => {
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setPageClass('register');
      setLoaded(true);
    }
  },[loaded]); 

  return <>
    <h1>Create Account</h1>
    <RegistrationForm />
  </>;
};

export default Registration;