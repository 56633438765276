import { useOutletContext } from 'react-router-dom';

const Four04 = () => {
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setPageClass('login');
      setLoaded(true);
    }
  },[loaded]); 
  
  return <h1>404</h1>;
};

export default Four04;